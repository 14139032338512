import React, { useEffect, useState } from "react";
import { loginBanner, logo } from "../assets/images";
import { Row, Col, Container, Image, Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../redux/user";

function Login() {
  const userDetails = useSelector((state) => state.user.userDetails);
  console.log(userDetails, "userDetails");
  const navigate = useNavigate();

  const [passwordShown, setPasswordShown] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (userDetails) {
      if (userDetails.error) {
        setError({ email: userDetails.error });
      } else if(userDetails.token) {
        setInpval({
          email: "",
          password: "",
        });
        navigate("/");
      }
    }
  }, [ userDetails]);

  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };
  const [inpval, setInpval] = useState({
    email: "",
    password: "",
    phone: "",
  });
  const [error, setError] = useState({});

  const signUpHandler = async (e) => {
    e.preventDefault();
    const { email, password } = inpval;
    const newErrors = {};

    function isNumber(value) {
      return !isNaN(value) && !isNaN(parseFloat(value));
    }

    if (!email) newErrors.email = "Email or Phone number is required";
    if (!password) {
      newErrors.password = "Password is required";
    }
    setError(newErrors);
    if (Object.keys(newErrors).length === 0) {
      if (isNumber(email)) {
        setInpval({ password: password, phone: email, email: "" });
        var newinput = {
          password: password,
          phone: email,
        };
        setInpval(newinput);
        console.log("number", newinput, inpval);
      } else {
        var newinput = {
          password: password,
          email: email,
        };
      }
      // Submit form data
      try {
        dispatch(loginUser(newinput));
      } catch (err) {
        console.error("Error:", err.response.data.error);
        setError({ email: err.response.data.error });
      }
    }
  };
  const handleChange = (e) => {
    setInpval({ ...inpval, [e.target.name]: e.target.value });
    setError({ ...error, [e.target.name]: null });
  };
  const [Check, setCheck] = useState(false);
  return (
    <Container className="gx-0" fluid>
      <Row className="gx-0">
        <Col lg="6" className="bg-orange-100 min-h-screen vstack p-6">
          <Link to="/">
            <Image width={200} src={logo} alt="therapyzone logo" />
          </Link>
          <div className="m-auto" style={{ maxWidth: "464px" }}>
            <h1 className="mb-3 mt-6 display-6 font-bold">Welcome back!</h1>
            <p className="mb-6 text-lg">Let's build someting great</p>
            <Form onSubmit={signUpHandler}>
              <Row className="gy-6 mb-6">
                <Col lg={12}>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Control
                      className="form-control-set"
                      type="text"
                      name="email"
                      placeholder={"E-mail address"}
                      value={inpval.email}
                      onChange={(e) => handleChange(e)}
                      isInvalid={!!error.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {error.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={12}>
                  <Form.Group className="d-flex position-relative align-items-center">
                    <Form.Control
                      type={passwordShown ? "text" : "password"}
                      className="form-control-set"
                      placeholder="Password"
                      name="password"
                      value={inpval.password}
                      onChange={(e) => handleChange(e)}
                      isInvalid={!!error.password}
                    />

                    {inpval.password ? (
                      passwordShown ? (
                        <FontAwesomeIcon
                          icon={faEye}
                          className="position-absolute end-5 cursor-pointer"
                          onClick={togglePassword}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faEyeSlash}
                          className="position-absolute end-5 cursor-pointer"
                          onClick={togglePassword}
                        />
                      )
                    ) : (
                      ""
                    )}
                    <Form.Control.Feedback type="invalid">
                      {error.password}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xs={6}>
                  <Form.Check type="checkbox" id="abc">
                    <Form.Check.Input
                      type="checkbox"
                      onClick={(e) => setCheck(e.target.checked)}
                    />
                    <Form.Check.Label className="font-bold text-sm">
                      Remember me
                    </Form.Check.Label>
                  </Form.Check>
                </Col>
                <Col xs={6} className="text-end">
                  <Link
                    to="/forgot/password"
                    className="font-bold link-primary text-sm"
                  >
                    Forgot your password ?
                  </Link>
                </Col>
              </Row>
              <Button variant="primary" className="w-full mb-6" type="submit">
                Log in
              </Button>
            </Form>
          </div>
          <p className="mt-auto text-center hstack flex-wrap justify-content-center gap-2">
            <span className="text-center">New at therapyzone?</span>
            <Link to="/register" className="font-bold link-primary">
              Create an account here
            </Link>
          </p>
        </Col>
        <Col
          lg="6"
          className="min-h-screen bg-sand-1 d-none d-lg-block bg-no-repeat bg-cover"
          style={{ backgroundImage: `url(${loginBanner})` }}
        ></Col>
      </Row>
    </Container>
  );
}

export default Login;
