import React, { useEffect, useState } from "react";
import { AdminHeader, AdminSidebar } from "../../components";
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Modal, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getAllSpaData } from "../../redux/bookspa";
import axios from "axios";

function AdminSpaActive() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getAllSpa = useSelector((state) => state.bookspa.getAllSpa);

  const [data,setData]=useState([]);
  const [show, setShow] = useState(false);
  const [spaId,setSpaId]=useState('')

  const handleClose = () => setShow(false);
  const handleShow = (spaid) => {
    if(spaid) setSpaId(spaid)
    setShow(true)};

  const handleLogout = () => {
    localStorage.clear();
    navigate("/admin/login");
  };
  useEffect(() => {
    if (getAllSpa.length === 0) {
      dispatch(getAllSpaData());
    }
  }, [getAllSpa]);

  useEffect(() => {
    if (getAllSpa && getAllSpa?.length !== 0) {
      setData(getAllSpa);
      console.log(getAllSpa,"getAllSpa34567890-");
      
    }
  }, [getAllSpa]);

  const handleSubmit = async() => {
    try {
      const response = await axios.patch(
       `${process.env.REACT_APP_API_KEY}admin/updateactive/${spaId}`
      );
      console.log(response, "response");
      if (response.status === 200) {
        dispatch(getAllSpaData());
        handleClose()
      }
    } catch (err) {
      console.error("Error:", err); 
    
    }
  }
  return (
    <>
  <div>
      <div className="d-flex flex-column flex-lg-row h-lg-full min-h-screen bg-gray-200 vstack">
        <AdminSidebar handleLogout={handleLogout} />
        <div className="flex-lg-1 h-lg-screen overflow-y-lg-auto">
          <AdminHeader title={"QR Code"} handleLogout={handleLogout} />
          <Container fluid className="py-6 gx-md-12">
          <Card>
            <CardHeader className="border-bottom">
              <h4>Spa Active</h4>
            </CardHeader>
            <CardBody className="p-0">
            <Table
                      responsive
                      striped
                      bordered
                      className="table-design"
                    >
                      <thead>
                        <tr>
                          <th>No</th>
                          <th>Name</th>
                          <th>Number</th>
                          <th>Email</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((item, index) => {
                          
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{item.name}</td>
                              <td>{item.phone_no}</td>
                              <td>{item.email}</td>
                              <td>{item.Active === true ? "Active" :  <Button type="submit" className="mt-3" onClick={()=>handleShow(item._id)}>
        unActive
      </Button>}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
            </CardBody>
            <CardFooter></CardFooter>
          </Card>
          </Container>
        </div>
      </div>
    </div>
    
    <Modal show={show} onHide={handleClose}>
  <Modal.Header closeButton>
    <Modal.Title>Spa Active Confirmation</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <h4>You Are Confirmed! Spa Active </h4>
    {/* <p>{appointmentData}</p> */}
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={handleClose}>
      Close
    </Button>
    <Button variant="primary" onClick={handleSubmit}>
      Done
    </Button>
  </Modal.Footer>
</Modal>

  </>
  );
}

export default AdminSpaActive;
