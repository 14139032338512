import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../apis/Api";

export const getPackage = createAsyncThunk(
  "getpackage/get",
  async (data, { rejectWithValue, dispatch }) => {
    console.log("api calling");

    try {
      const backend = new API();
      const response = await backend.fetch(API.PackageList(), {
        method: "GET",
      });
      console.log(response, "response");

      // ToastMessage(response);
      // You may want to return some data here if needed
      return response;
    } catch (error) {
      console.error("Error send otp:", error);
      // You can handle the error here or throw it again if needed
      return rejectWithValue(error);
    }
  }
);

export const addPackage = createAsyncThunk(
  "addpackage/post",
  async (data, { rejectWithValue, dispatch }) => {
    console.log("api calling");
    const formData = new FormData();
    for (const key in data) formData.append(key, data[key]);
    try {
      const backend = new API();
      const response = await backend.fetch(API.PackageAdd(), {
        method: "POST",
        body: formData,
      });
      console.log(response, "response9090909090909090909");

      // ToastMessage(response);
      // You may want to return some data here if needed
      return response;
    } catch (error) {
      console.error("Error send otp:", error);
      // You can handle the error here or throw it again if needed
      return rejectWithValue(error);
    }
  }
);
