import React from 'react'
import { Button, Card, CardBody, Container, Stack } from 'react-bootstrap'
import { Footer, Header } from '../components'
import { Link } from 'react-router-dom'
import { HiMiniCheckCircle } from "react-icons/hi2";


function Success() {
  return (
    <>
    <Header />
    <Container>
    <Stack direction="vertical" className='min-h-screen-banner justify-content-center align-items-center'>
        <Card className='border max-w-screen-sm'>
            <CardBody>
            <HiMiniCheckCircle className='display-3 text-success mb-4' />
            <h1 className='font-bolder mb-2'>Payment succeeded</h1>
            <p className='mb-4'>Thanks for your recent payment! Your premium subscription is now officially active. Enjoy the perks!</p>
            <Button as={Link} to={"/user/package"}variant="primary">Your Subscription</Button>
            </CardBody>
        </Card>
    </Stack>
    </Container>
    <Footer />
    </>
  )
}

export default Success