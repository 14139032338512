import React, { useEffect, useState } from "react";
import {
  Navbar,
  Nav,
  Collapse,
  Dropdown,
  Button,
  Image,
  NavDropdown,
} from "react-bootstrap";
import { logo } from "../../assets/images";
import { GrAppsRounded, GrList } from "react-icons/gr";
import { GoPackage } from "react-icons/go";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

function AdminSidebar({handleLogout}) {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [openUserMenu, setOpenUserMenu] = useState(false);
  const [role, setRole] = useState(localStorage.getItem("roles") || "");

  const userRole = useSelector((state) => state.user.userRole);

  useEffect(() => {
    if (userRole) setRole(userRole);
    console.log(userRole, "userRole");
  }, [userRole]);

  return (
    <Navbar
      className="navbar-vertical h-lg-screen navbar-expand-lg px-0 py-3 navbar-light bg-white border-bottom border-bottom-lg-0 border-end-lg mb-0"
      expand="lg"
      id="sidebar"
    >
      <div className="container-fluid gx-md-12">
        <Navbar.Toggle aria-controls="sidebarCollapse" />
        <Navbar.Brand
          to="/"
          as={Link}
          className="d-inline-block py-lg-2 py-0 mb-lg-5 px-lg-6 me-0"
        >
          <Image
            width={204}
            src={logo}
            alt="therapy zone"
            className="w-lg-auto w-sm-40 w-32"
          />
        </Navbar.Brand>

        {/* Mobile user dropdown */}
        <div className="navbar-user d-lg-none">
          <Dropdown>
            <Dropdown.Toggle
              id="sidebarAvatar"
              className="d-flex align-items-center gap-2"
              as={Button}
            >
              <div className="d-sm-block d-none">
                <span className="h6 d-block">Admin Techvoot</span>
                <span className="text-xs text-muted d-block">
                  Administrator
                </span>
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu align="end">
              <Dropdown.Item href="https://beta.techvoot.com/admin/profile">
                <i className="bi bi-person-vcard me-3"></i>Edit profile
              </Dropdown.Item>
              <Dropdown.Item
               onClick={()=>handleLogout()}
              >
                <i className="bi bi-box-arrow-left me-3"></i>
                <span>Logout 222</span>
              </Dropdown.Item>
              <form
                id="logout-form"
                action="https://beta.techvoot.com/logout"
                method="POST"
                className="d-none"
              >
                <input
                  type="hidden"
                  name="_token"
                  value="YJPkU1Ct33SOBLms8kFNkGAm84sNwfzKvbfltU1t"
                />
              </form>
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <Navbar.Collapse id="sidebarCollapse" className="px-4">
          <Nav className="custome-navbar">
            {role === "spa_owner" && (
              <>
                <Nav.Item>
                  <Nav.Link
                    as={Link}
                    to="/admin/package"
                    className={`${
                      location.pathname.includes("/admin/package")
                        ? "active"
                        : ""
                    }`}
                  >
                    <GoPackage />
                    Customer Details
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    as={Link}
                    to="/admin/qrcode"
                    className={`${
                      location.pathname.includes("/admin/qrcode")
                        ? "active"
                        : ""
                    }`}
                  >
                    <GoPackage />
                    Create QrCode
                  </Nav.Link>
                </Nav.Item>
              </>
            )}
            {role === "admin" && (
              <>
                <Nav.Item>
                  <Nav.Link
                    as={Link}
                    to="/admin/dashboard"
                    className={`${
                      location.pathname.includes("/admin/dashboard")
                        ? "active"
                        : ""
                    }`}
                  >
                    <GrAppsRounded />
                    Dashboard
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    as={Link}
                    to="/admin/addpackage"
                    className={`${
                      location.pathname.includes("/admin/addpackage")
                        ? "active"
                        : ""
                    }`}
                  >
                    <GoPackage />
                    Package
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    as={Link}
                    to="/admin/spa/active"
                    className={`${
                      location.pathname.includes("/admin/spa/active")
                        ? "active"
                        : ""
                    }`}
                  >
                    <GrAppsRounded />
                    Spa Active
                  </Nav.Link>
                </Nav.Item>
              </>
            )}
            {role === "user" && (
              <>
                <Nav.Item>
                  <Nav.Link
                    as={Link}
                    to="/user/package"
                    className={`${
                      location.pathname.includes("/user/package")
                        ? "active"
                        : ""
                    }`}
                  >
                    <GoPackage />
                     Package
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    as={Link}
                    to="/user/scanner"
                    className={`${
                      location.pathname.includes("/user/scanner")
                        ? "active"
                        : ""
                    }`}
                  >
                    <GoPackage />
                    Scanner
                  </Nav.Link>
                </Nav.Item>
              </>
            )}

            {/* <Nav.Item>
              <Nav.Link to="/package"><GrList />Type of Spa</Nav.Link>
            </Nav.Item> */}
            {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown> */}
            {/* <Nav.Item>
              <Button
                variant="link"
                className="nav-link w-full"
                onClick={() => setOpenUserMenu(!openUserMenu)}
                aria-expanded={openUserMenu}
              >
                <i className="bi bi-people"></i> User
              </Button>
              <Collapse in={openUserMenu}>
                <div>
                  <Nav className="nav-sm flex-column">
                    <Nav.Item>
                      <Nav.Link href="https://beta.techvoot.com/admin/user/leaves">Leaves</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
              </Collapse>
            </Nav.Item> */}
            {/* <Nav.Item>
            <Nav.Link
        onClick={() => setOpen(!open)}
        aria-controls="Admin"
        aria-expanded={open}
        role="button"
        className="d-flex align-items-center active"
      >
        Admin
      </Nav.Link>

      <Collapse in={open}>
        <div id="Admin">
          <Nav className="flex-column">
            <Nav.Item>
              <Nav.Link href="https://beta.techvoot.com/admin/employees">Employees</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="https://beta.techvoot.com/admin/roles">Roles</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="https://beta.techvoot.com/admin/assets">User Assets</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="https://beta.techvoot.com/admin/salary-slip">Salary Slip</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="https://beta.techvoot.com/admin/old-salary-slip">Old Salary Slip</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="https://beta.techvoot.com/admin/employee-track-email">Email Tracker</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="https://beta.techvoot.com/admin/employee-email-track-unsubscribe">Email Track Unsubscribe</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="https://beta.techvoot.com/admin/employee-track-check-emails">Email Track Remove</Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
      </Collapse>
            </Nav.Item> */}
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
}

export default AdminSidebar;
