import React from 'react'
import { Footer, Header } from '../components'
import { Card, CardBody, Col, Container, Form, Image, Row, Button } from 'react-bootstrap'

function ContactUs() {
  return (
    <>
      <Header />
      <section className='bg-gray-200'>
        <Container className='py-lg-16 py-10'>
          <Row className='mb-8 align-items-center'>
            <Col lg={6}>
              <h1 className="text-wood-gradient font-bolder mb-4">Contact Us</h1>
              {/* <p className='mb-2'>Got questions about our products or scaling on our platform? We’re here to help!</p> */}
              <p className='mb-2'>Our friendly team is available 24/7 to assist you. Whether you need support or want to get onboard quickly, we’ve got you covered—get started in less than 5 minutes!</p>
              {/* <p>Feel free to reach out through our chat feature below, or contact us via email or phone. We look forward to connecting with you!</p> */}
              <Form>
                <Row className='gy-6'>
                  <Col md={6}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>First name</Form.Label>
                      <Form.Control type="email" placeholder="First name" />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Last name</Form.Label>
                      <Form.Control type="email" placeholder="Last name" />
                    </Form.Group></Col>
                  <Col md={6}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Email</Form.Label>
                      <Form.Control type="email" placeholder="you@gmail.com" />
                    </Form.Group></Col>
                  <Col md={6}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Phone number</Form.Label>
                      <Form.Control type="email" placeholder="Phone number" />
                    </Form.Group></Col>
                  <Col sm={12}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Message</Form.Label>
                      <Form.Control as="textarea" rows={3} />
                    </Form.Group></Col>
                <Col sm={12}>
                <Button variant="dark" type="submit" className='px-lg-20 px-6'>
                    Submit
                  </Button></Col>
                </Row>
              </Form>
            </Col>
            <Col lg={6}>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3671.5177648120607!2d72.6759476747708!3d23.041472015623643!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e87fe79c0f5a3%3A0xd73832a9d13e076f!2sABC%20BUSINESS%20HUB!5e0!3m2!1sen!2sin!4v1727096487819!5m2!1sen!2sin" width="600" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" className='border-0'></iframe>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  )
}

export default ContactUs