import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../apis/Api";
import { AddUserRole } from "./userSlice";
// import { useNavigate } from "react-router";

export const loginUser = createAsyncThunk(
  "loginUser/post",
  async (data, { rejectWithValue, dispatch }) => {
    // const navigate = useNavigate();
    // Log the data as a JSON string for better readability
    console.log("API calling with data:", JSON.stringify(data));

    try {
      const backend = new API();
      const response = await backend.fetch(API.LoginApi(), {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Set Content-Type header
        },
        body: JSON.stringify(data), // Send the data as JSON
      });

      // Optionally, handle any success messages or logic
      if (response.user.role) {
        await localStorage.setItem("roles", response.user.role);
        dispatch(AddUserRole(response.user.role))
        await localStorage.setItem("therapyToken", response.token);
      }
      return response;
    } catch (error) {
      console.error("Error during login:", error);
      // Handle the error and return it for rejection
      return rejectWithValue(error);
    }
  }
);

export const updateUserProfile = createAsyncThunk(
  "updateUserProfile/put",
  async (data, { rejectWithValue, dispatch }) => {
    // const navigate = useNavigate();
    // Log the data as a JSON string for better readability
    console.log("API calling with data:", JSON.stringify(data));
const id = data._id
    try {
      const backend = new API();
      const response = await backend.fetch(API.UpdateUser(id), {
        method: "Put",
        headers: {
          "Content-Type": "application/json", // Set Content-Type header
        },
        body: JSON.stringify(data), // Send the data as JSON
      });

      // Optionally, handle any success messages or logic
     console.log(response,"response123456789");
     if(response.user){
      dispatch(userDetailData())
     }
      
      return response;
    } catch (error) {
      console.error("Error during login:", error);
      // Handle the error and return it for rejection
      return rejectWithValue(error);
    }
  }
);


export const loginAdmin = createAsyncThunk(
  "loginAdmin/post",
  async (data, { rejectWithValue, dispatch }) => {

    try {
      const backend = new API();
      const response = await backend.fetch(API.LoginAdminApi(), {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Set Content-Type header
        },
        body: JSON.stringify(data), // Send the data as JSON
      });

      // Optionally, handle any success messages or logic
      if (response.user.role) {
        await localStorage.setItem("roles", response.user.role);
        dispatch(AddUserRole(response.user.role))
        await localStorage.setItem("therapyToken", response.token);
      }
      return response;
    } catch (error) {
      console.error("Error during login:", error);
      // Handle the error and return it for rejection
      return rejectWithValue(error);
    }
  }
);

export const loginSpaApi = createAsyncThunk(
  "loginSpaApi/post",
  async (data, { rejectWithValue, dispatch }) => {
    // Log the data as a JSON string for better readability
    // const navigate = useNavigate();
    try {
      const backend = new API();
      const response = await backend.fetch(API.SpaLoginApi(), {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Set Content-Type header
        },
        body: JSON.stringify(data), // Send the data as JSON
      });
      if (response.user.role) {
        await localStorage.setItem("roles", response.user.role);
        dispatch(AddUserRole(response.user.role))
        await localStorage.setItem("therapyToken", response.token);
        // navigate('/admin/package')
      }

      // Optionally, handle any success messages or logic
      return response;
    } catch (error) {
      console.error("Error during login:", error);
      // Handle the error and return it for rejection
      return rejectWithValue(error);
    }
  }
);

export const userDetailData = createAsyncThunk(
  "userDetailData/get",
  async (data, { rejectWithValue, dispatch }) => {
    // Log the data as a JSON string for better readability

    try {
      const backend = new API();
      const response = await backend.fetch(API.LoginApiData(), {
        method: "GET",
      });
      console.log(response, "response");

      if (response.status === 401) {
        localStorage.clear();
        console.log("555555555555555555555555555555555");
      }
      // Optionally, handle any success messages or logic
      return response;
    } catch (error) {
      console.error("Error during login:", error);
      // Handle the error and return it for rejection
      return rejectWithValue(error);
    }
  }
);
