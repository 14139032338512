import React from 'react'
import { Button, Card, CardBody, Container, Stack } from 'react-bootstrap'
import { Footer, Header } from '../components'
import { Link } from 'react-router-dom';
import { MdCancel } from 'react-icons/md';


function Failed() {
  return (
    <>
    <Header />
    <Container>
    <Stack direction="vertical" className='min-h-screen-banner justify-content-center align-items-center'>
        <Card className='border max-w-screen-sm'>
            <CardBody>
            <MdCancel className='display-3 text-danger mb-4' />
            <h1 className='font-bolder mb-2'>Payment Unsuccessful</h1>
            <p className='mb-4'>We’re sorry, but your recent payment could not be processed. Please check your payment information and try again. If you need assistance, feel free to reach out to our support team.</p>
            <Button as={Link} to={"/packages"}variant="primary">Your Packages</Button>
            </CardBody>
        </Card>
    </Stack>
    </Container>
    <Footer />
    </>
  )
}

export default Failed