import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Container, Button, Modal } from "react-bootstrap";
import Webcam from "react-webcam";
import jsQR from "jsqr";
import { useDispatch, useSelector } from "react-redux";
import { bookspaAdd } from "../redux/bookspa";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";

function ForgotPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const bookspa = useSelector((state) => state.bookspa.bookspa);
  console.log(bookspa, "bookspa");

  const options = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progressStyle: { background: "#F54E15" },
    progress: undefined,
    theme: "dark",
  };


  useEffect(() =>{
    if(bookspa.message){
      toast.error(bookspa.message, options)
    }
    if(bookspa.spaData){
      toast.success("Your Appointment Confirmed", options)
      navigate("/user/package")
    }
  }, [bookspa]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [data, setData] = useState("");
  const [camera, setCamera] = useState("environment");
  const webcamRef = useRef(null);
  const [scanning, setScanning] = useState(true); // Flag to control scanning

  useEffect(() => {
    if (scanning) {
      const intervalId = setInterval(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        if (imageSrc) {
          const img = new Image();
          img.src = imageSrc;
          img.onload = () => {
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");
            canvas.height = img.height;
            canvas.width = img.width;
            context.drawImage(img, 0, 0, img.width, img.height);
            const imageData = context.getImageData(0, 0, img.width, img.height);
            const code = jsQR(imageData.data, img.width, img.height);
            if (code) {
              setData(code.data);
              setScanning(false);
              handleShow();
            }
          };
        }
      }, 1000); // Scan every 1000 ms (1 second)

      return () => clearInterval(intervalId); // Clean up on component unmount
    }
  }, [scanning]);

  const toggleCamera = () => {
    setCamera((prevCamera) =>
      prevCamera === "environment" ? "user" : "environment"
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (data) {
      dispatch(
        bookspaAdd({
          spaId: data,
        })
      );
      handleClose();
    }
  };

  return (
    <>
      <Container className="gx-0" fluid>
        {/* <Row className="gx-0">
        <Col lg="6" className="bg-orange-100 min-h-screen vstack p-6">
          <Link to="/">
            <Image width={200} src={logo} alt="therapyzone logo" />
          </Link>
          <div className="m-auto" style={{ maxWidth: "464px" }}>
            <h1 className="mb-3 mt-6 display-6 font-bold">Password Reset</h1>
            <p className="mb-6 text-lg">
              Enter your email and we will send you a reset link
            </p>
            <Form onSubmit={signUpHandler}>
              <Row className="gy-4 gx-4 mb-6">
                <Form.Group controlId="formBasicEmail">
                  <Form.Control
                    className="form-control-set"
                    type="email"
                    name="email"
                    placeholder={"E-mail address"}
                    value={inpval.email}
                    onChange={e => handleChange(e)}
                    isInvalid={!!error.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {error.email}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Button variant="primary" className="w-full mb-6" type="submit">
                Send Password Reset Link
              </Button>
            </Form>
          </div>
          <p className="mt-auto text-center">
            <span className="me-4">Already have an account ?</span>
            <Link to="/login" className="font-bold link-primary">
              Log in
            </Link>
          </p>
        </Col>
        <Col
          lg="6"
          className="min-h-screen bg-sand-1 d-none d-lg-block bg-no-repeat bg-cover"
          style={{ backgroundImage: `url(${passwordResetBanner})` }}
        ></Col>
      </Row> */}
        <Row>
          <Col>
            <div>
              <h1>QR Code Scanner</h1>
              <h2>Data: {data}</h2>
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                width="100%"
                height="100%"
                videoConstraints={{ facingMode: camera }}
              />
              <Button onClick={toggleCamera}>
                Switch to {camera === "environment" ? "Front" : "Back"} Camera
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Appointment Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Your Appointment is ganga spa in Confirmed!</h4>
          <p>Details:</p>
          {/* <p>{appointmentData}</p> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Done
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ForgotPassword;
