import { configureStore } from "@reduxjs/toolkit";
import packageSlice from "./packages/packagesSlice";
import userSlice from "./user/userSlice";
import QRCodeSlice from "./qrcode/qrCodeSlice";
import bookspaSlice from "./bookspa/bookSpaSlice";
const store = configureStore({
  reducer: {
    user:userSlice,
    package: packageSlice,
    qrcode:QRCodeSlice,
    bookspa:bookspaSlice,
  },
});

export default store;
