import React from 'react'
import { Button, Card, CardBody, Col, Container, Image, Row } from 'react-bootstrap'
import { MdArrowOutward, MdCurrencyRupee } from 'react-icons/md'
import { Link, useNavigate } from 'react-router-dom'
import { Footer, Header } from '../components'

function PackagesDetails() {
  return (
    <>
      <Header />
      <section className='my-lg-20 my-10'>
        <Container >

          <Row className='mb-8 align-items-center'>
            <Col lg={6}>
              <Image src="https://i0.wp.com/letsrelaxspa.com/wp-content/uploads/2022/01/aromas-used-for-massages-2-scaled.jpg" alt="Hospitality Tourism" loading="lazy" />
              {/* <Card className='h-full bg-gray-200'>
                <CardBody className='p-0 overflow-hidden position-relative packages-card'>
                  <div className='position-relative overlap-10 ms-4 mt-32'>
                    <h3 className='mb-2 text-light'>Relax / Oil Massage</h3>
                    <p className='mb-4 text-gray-200'>Use any tie-up spa 5 time</p>
                  </div>
                </CardBody>
                <CardBody className='border-top pt-4'>
                </CardBody>
              </Card> */}
            </Col>
            <Col lg={6}>
              <h1 className="text-wood-gradient font-bolder mb-4">SIMPLI SPA Membership</h1>
              <h4 ><MdCurrencyRupee />1200 / <span>60</span> minute</h4>
              <span className='mb-4 d-block'>Inclusive of all Taxes</span>
              <p className='mb-4'>Your path to relaxation awaits! Take the first step today with Simpli Spa Membership. Unlock access to a realm of sublime wellness spa therapies, carefully tailored to combat the symptoms of stress and weariness caused by our contemporary lifestyles.</p>
              <Button variant="primary" size='lg' className="">BUY NOW</Button>
            </Col>
          </Row>
        </Container>
      </section>
      <section className='bg-orange-200 py-lg-18 py-10 mb-lg-20 mb-10'>
        <Container>
        <h2 className='text-wood-gradient font-bolder mb-6'>Benefits of Membership</h2>
              <ul className="list-check-1 list-unstyled vstack gap-3 mb-0 h4">
                <li className="">Membership can be redeemed in any of our spa locations, unless specified.</li>
                <li className="">Special price on all services.</li>
                <li className="">Membership is transferable & shareable.</li>
                <li className="">Preferential appointment booking for members.</li>
              </ul>
        </Container>
      </section>
      <section className='mb-lg-20 mb-20'>
        <Container>
          <Card className='h-full bg-gray-200'>
            <CardBody className='py-lg-10'>
              <h2 className='text-wood-gradient font-bolder mb-6'>Helth Benefits</h2>
              <h4 className="">1. Relaxation and Stress Relief</h4>
              <ul className="list-check-1 list-unstyled vstack gap-3 mb-4">
                <li className="">Spa treatments, such as massages and aromatherapy, promote relaxation, helping to reduce stress and anxiety.</li>
              </ul>
              <h4 className="">2. Improved Circulation</h4>
              <ul className="list-check-1 list-unstyled vstack gap-3 mb-4">
                <li className="">Many spa therapies, especially massages, stimulate blood flow, enhancing oxygen and nutrient delivery to tissues.</li>
              </ul>
              <h4 className="">3. Pain Relief</h4>
              <ul className="list-check-1 list-unstyled vstack gap-3 mb-4">
                <li className="">Spa therapies can alleviate chronic pain conditions, such as arthritis and muscle soreness, through techniques like heat therapy and massage.</li>
              </ul>
              <h4 className="">4. Skin Health</h4>
              <ul className="list-check-1 list-unstyled vstack gap-3 mb-4">
                <li className="">Treatments like facials and body scrubs improve skin tone and texture, remove dead skin cells, and hydrate the skin.</li>
              </ul>
              <h4 className="">5. Detoxification</h4>
              <ul className="list-check-1 list-unstyled vstack gap-3 mb-4">
                <li className="">Saunas and steam baths promote sweating, which can help eliminate toxins from the body.</li>
              </ul>
              <h4 className="">6. Enhanced Sleep Quality</h4>
              <ul className="list-check-1 list-unstyled vstack gap-3 mb-4">
                <li className="">The relaxation experienced during spa treatments can improve sleep quality and help combat insomnia.</li>
              </ul>
              <h4 className="">7. Increased Flexibility and Mobility</h4>
              <ul className="list-check-1 list-unstyled vstack gap-3 mb-4">
                <li className="">Regular massage and bodywork can improve flexibility and joint mobility, reducing stiffness.</li>
              </ul>
              <h4 className="">8. Boosted Immune System</h4>
              <ul className="list-check-1 list-unstyled vstack gap-3 mb-4">
                <li className="">Regular spa visits may enhance immune function by reducing stress hormones and improving overall health.</li>
              </ul>
              <h4 className="">9. Mental Clarity and Focus</h4>
              <ul className="list-check-1 list-unstyled vstack gap-3 mb-4">
                <li className="">Relaxation techniques can improve cognitive function and mental clarity, leading to better focus and productivity.</li>
              </ul>
              <h4 className="">10. Social Interaction</h4>
              <ul className="list-check-1 list-unstyled vstack gap-3 mb-0">
                <li className="">Many spas offer group classes or social spaces, which can enhance feelings of well-being through social interaction.</li>
              </ul>
              
            </CardBody>
          </Card>
        </Container>
      </section>
      <Footer />
    </>
  )
}
export default PackagesDetails;