import { createSlice } from "@reduxjs/toolkit";
import {
  getPackage,
  addPackage
} from "./index";

const packageSlice = createSlice({
  name: "packageSlice",
  initialState: {
    allPackages: {},
    packageAdd:{},
    allPackagesIsLoading: false,
    addPackageIsLoading:false,
    error: null,
  },
  reducers: {
   
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPackage.pending, (state) => {
        state.allPackagesIsLoading = true;
      })
      .addCase(getPackage.fulfilled, (state, action) => {
        state.allPackages = action.payload;
        state.allPackagesIsLoading = false;
      })
      .addCase(getPackage.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
        state.allPackagesIsLoading = false;
      }).addCase(addPackage.pending, (state) => {
        state.addPackageIsLoading = true;
      })
      .addCase(addPackage.fulfilled, (state, action) => {
        state.packageAdd = action.payload;
        state.addPackageIsLoading = false;
      })
      .addCase(addPackage.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
        state.addPackageIsLoading = false;
      })
  },
});

export default packageSlice.reducer;
