import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { aboutUs, happyClients, spaLocation, spaTreatments, therapists, yearsExperience } from '../assets/images'
import { Footer, Header } from '../components'

function AboutUs({userDetails}) {
  return (
    <>
      <Header userDetails={userDetails}/>
      <section className='my-lg-20 my-10'>
        <Container>
          <Row className='align-items-center gy-6'>
            <Col lg={6} className="order-lg-1 order-2">
              <span className="d-block mb-2">Our Story</span>
              <h1 className="mb-5 font-bolder">Therapy Zone</h1>
              <p>Prithivi, Akash, Jal, Agni, Vayu (Earth, Sky, Water, Fire, Air) are the five therapy zone or elements
                that the universe and the human body is composed of. Ancient eastern philosophy holds that the
                balance of these five therapy zone or elements in entire universe, including the human body, is the
                essence of wellness. Balance. That is what therapy zone aims to strive for your mind, body and
                soul.</p>
            </Col>
            <Col lg={6} className="order-lg-2 order-1">
              <Image src={aboutUs} />
            </Col>
          </Row>
        </Container>
      </section>
      <section className='mb-lg-20 mb-10'>
        <Container>
          <Row className='align-items-center gy-6'>
            <Col lg={6}>
              <Image src={spaLocation} width="680" alt='our location' />
            </Col>
            <Col lg={6}>
              <span className="d-block mb-2">Our Location</span>
              <h1 className="mb-5 font-bolder">LOCATIONS</h1>
              <p>Since opening that first in 2018 in the quaint ahmedabad, we have come a long way with our Spas now located across 70+ locations. Whether it be a located in the heart of the city or our located in idyllic resort destinations, you can be assured of the warm hospitality and professional nature of services. Magnificently accoutred, therapy zone spaces evokes the feelings of tranquillity, balance, and serenity in the consumer by using elements from nature, beautiful landscapes, that and a pastel soothing colour pallette.</p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className='bg-gray-200 py-lg-20 py-14'>
        <Container>
          <Row className='align-items-center gy-12'>
            <Col lg={3} sm={2}>
              <div className='hstack gap-4'>
                <Image src={yearsExperience} width={64} className='h-16 object-contain' alt='Years Experience' />
                <div>
                  <h2 className="font-60 text-primary-hover">6</h2>
                  <p>Years Experience</p>
                </div>
              </div>
            </Col>
            <Col lg={3} sm={2}>
              <div className='hstack gap-4'>
                <Image src={therapists} width={64} className='h-16 object-contain' alt='Years Experience' />
                <div>
                  <h2 className="font-60 text-primary-hover">16</h2>
                  <p>Therapists</p>
                </div>
              </div>
            </Col>
            <Col lg={3} sm={2}>
              <div className='hstack gap-4'>
                <Image src={spaTreatments} width={64} className='h-16 object-contain' alt='Years Experience' />
                <div>
                  <h2 className="font-60 text-primary-hover">16</h2>
                  <p>Spa Treatments</p>
                </div>
              </div>
            </Col>
            <Col lg={3} sm={2}>
              <div className='hstack gap-4'>
                <Image src={happyClients} width={64} className='h-16 object-contain' alt='Years Experience' />
                <div>
                  <h2 className="font-60 text-primary-hover">125</h2>
                  <p>Happy Clients</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  )
}

export default AboutUs