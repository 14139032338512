import { createSlice } from "@reduxjs/toolkit";
import { bookspaAdd, getUserBookSpa,getAdminBookSpa, getAllSpaData } from "./index";

const bookspaSlice = createSlice({
  name: "bookspaSlice",
  initialState: {
    bookspa: {},
    getUserSpaBook: {},
    getBookSpaAdmin:[],
    getAllSpa:[],
    bookspaIsLoading: false,
    getBookSpaAdminIsLoading: false,
    getUserSpaBookIsLoading: false,
    getAllSpaIsLoading:false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(bookspaAdd.pending, (state) => {
        state.bookspaIsLoading = true;
      })
      .addCase(bookspaAdd.fulfilled, (state, action) => {
        state.bookspa = action.payload;
        state.bookspaIsLoading = false;
      })
      .addCase(bookspaAdd.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
        state.bookspaIsLoading = false;
      })
      .addCase(getUserBookSpa.pending, (state) => {
        state.getUserSpaBookIsLoading = true;
      })
      .addCase(getUserBookSpa.fulfilled, (state, action) => {
        state.getUserSpaBook = action.payload;
        state.getUserSpaBookIsLoading = false;
      })
      .addCase(getUserBookSpa.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
        state.getUserSpaBookIsLoading = false;
      }).addCase(getAdminBookSpa.pending, (state) => {
        state.getBookSpaAdminIsLoading = true;
      })
      .addCase(getAdminBookSpa.fulfilled, (state, action) => {
        state.getBookSpaAdmin = action.payload;
        state.getBookSpaAdminIsLoading = false;
      })
      .addCase(getAdminBookSpa.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
        state.getBookSpaAdminIsLoading = false;
      }).addCase(getAllSpaData.pending, (state) => {
        state.getAllSpaIsLoading = true;
      })
      .addCase(getAllSpaData.fulfilled, (state, action) => {
        state.getAllSpa = action.payload;
        state.getAllSpaIsLoading = false;
      })
      .addCase(getAllSpaData.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
        state.getAllSpaIsLoading = false;
      });
  },
});

export default bookspaSlice.reducer;
