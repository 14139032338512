import React, { useEffect, useState } from "react";
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  Image,
  Row,
  Table,
} from "react-bootstrap";
import { GoPackage } from "react-icons/go";
import { GrList } from "react-icons/gr";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { getAdminBookSpa } from "../../redux/bookspa";
import { AdminHeader, AdminSidebar } from "../../components";
import { useNavigate } from "react-router";

function AdminPackage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getAdminSpaBook = useSelector((state) => state.bookspa.getBookSpaAdmin);
  const storedRole = localStorage.getItem("roles");
  const token = localStorage.getItem("therapyToken");


  useEffect(() => {
    if (getAdminSpaBook && isObjectEmpty(getAdminSpaBook)) {
      dispatch(getAdminBookSpa());
    }
  }, [getAdminSpaBook]);

  useEffect(()=>{
    if(token&&storedRole){
      navigate("/admin/package")
    }else{
      navigate("/spa/login")
    }
  },[token,storedRole])

  function isObjectEmpty(data) {
    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  useEffect(() => {
    if (getAdminSpaBook && getAdminBookSpa?.length !== 0) {
      setData(getAdminSpaBook);
    }
  }, [getAdminSpaBook]);
  const [data, setData] = useState([]);

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 4;

  const pageCount = Math.ceil(data.length / itemsPerPage);
  const offset = currentPage * itemsPerPage;
  const currentPageData = data.slice(offset, offset + itemsPerPage);

  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setCurrentPage(selectedPage);
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/spa/login")
  }
  return (
    <div className="d-flex flex-column flex-lg-row h-lg-full min-h-screen bg-gray-200 vstack">
      <AdminSidebar  handleLogout={handleLogout}/>
      <div className="flex-lg-1 h-lg-screen overflow-y-lg-auto">
        <AdminHeader title={"Customer Details"}  handleLogout={handleLogout}/>
        <Container fluid className="py-6 gx-md-12">
          <h1>Customer Details</h1>
          <Row>
            <Card>
              {data ? (
                <>
                  <Card.Body className="p-0">
                    <Table
                      responsive
                      striped
                      bordered
                      hover
                      className="table-design"
                    >
                      <thead>
                        <tr>
                          <th>No</th>
                          <th>Name</th>
                          <th>Number</th>
                          <th>UseCode</th>
                          <th>Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((item, index) => {
                          // Parse createdAt to a Date object
                          const createdAtDate = new Date(item.createdAt);

                          // Format date
                          const options = {
                            weekday: "long",
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: false,
                          };
                          const formattedDate = createdAtDate
                            .toLocaleDateString("en-GB", options)
                            .replace(",", "")
                            .replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3"); // format to dd/mm/yyyy

                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{item.customerId.name}</td>
                              <td>{item.customerId.phone_no}</td>
                              <td>{item.useCodeId}</td>
                              <td>{formattedDate}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Card.Body>
                  <Card.Footer>
                    <div className="d-flex justify-content-between align-items-center">
                      <p>
                        Showing {offset + 1} to{" "}
                        {offset + currentPageData.length} of {data.length}{" "}
                        entries
                      </p>

                      <ReactPaginate
                        previousLabel={"previous"}
                        nextLabel={"next"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </Card.Footer>
                </>
              ) : (
                <p className="text-center">No data available</p>
              )}{" "}
            </Card>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default AdminPackage;
