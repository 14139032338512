import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Image, Row } from "react-bootstrap";
import { Footer, Header } from "../components";
import { useDispatch, useSelector } from "react-redux";
import { userDetailData,updateUserProfile } from "../redux/user";

function Profile() {
  const userDetails = useSelector((state) => state.user.userDetails);
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone_no: "",
    password: "",
    role: "",
    _id: "",
    profile_photo:""
  });

  const [imageSrc, setImageSrc] = useState();
  const [errors, setErrors] = useState({});

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        console.log(reader.result,"reader.result");
        
        setImageSrc(reader.result);
        setFormData({ ...formData, 'profile_photo': reader.result });
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (
      localStorage.getItem("therapyToken") &&
      localStorage.getItem("roles") === "user"
    ) {
      if (!userDetails.user) {
        dispatch(userDetailData());
      } else {
        setFormData({
          name: userDetails.user.name || "",
          email: userDetails.user.email || "",
          phone_no: userDetails.user.phone_no || "",
          password: userDetails.user.password || "",
          role: userDetails.user.role || "",
          _id: userDetails.user._id || "",
          profile_photo:userDetails.user.profile_photo || ''
        });
      }
    }
  }, [userDetails, dispatch]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Name is required";
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.phone_no) newErrors.phone_no = "phone_no number is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      // Call your update user API
      console.log(formData, "formData");

      await dispatch(updateUserProfile(formData));
      // Handle success or error response
    }
  };

  return (
    <>
      <Header />
      <div className="bg-gray-200">
        <Container className="py-lg-20 py-10">
          <h1 className="mb-6 font-bolder text-primary text-center">Profile</h1>
          <Form onSubmit={handleSubmit}>
            <Row className="gy-3">
              <Col sm={12}>
                <Form.Group controlId="formFile" className="text-center">
                  <Image
                    src={formData.profile_photo}
                    width="400px"
                    height="400px"
                    className="avatar avatar-2xl rounded-circle object mb-6"
                    onClick={() => document.getElementById("fileInput").click()}
                    alt="Profile"
                  />
                  <Form.Label
                    className="btn btn-sm btn-dark mb-0 d-block max-w-screen-xs mx-auto"
                    htmlFor="fileInput"
                  >
                    Change Profile
                  </Form.Label>
                  <Form.Control
                    id="fileInput"
                    type="file"
                    className="d-none"
                    onChange={handleFileChange}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    size="lg"
                    type="text"
                    name="name"
                    value={formData.name}
                    placeholder="Your Name"
                    onChange={handleChange}
                    isInvalid={!!errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group>
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    size="lg"
                    type="email"
                    name="email"
                    value={formData.email}
                    placeholder="name@example.com"
                    onChange={handleChange}
                    isInvalid={!!errors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group>
                  <Form.Label>Mobile Number</Form.Label>
                  <Form.Control
                    size="lg"
                    type="text"
                    name="phone_no"
                    value={formData.phone_no}
                    placeholder="12345 67890"
                    onChange={handleChange}
                    isInvalid={!!errors.phone_no}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.phone_no}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={12} className="text-lg-end text-center">
                <Button variant="primary" size="lg" type="submit">
                  Save Profile
                </Button>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default Profile;
