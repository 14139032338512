import { createSlice } from "@reduxjs/toolkit";
import { loginUser, userDetailData , loginSpaApi,loginAdmin} from "./index";

const userSlice = createSlice({
  name: "userSlice",
  initialState: {
    userDetails: {},
    spaDetails:{},
    adminDetails:{},
    userRole:'',
    userDetailsIsLoading: false,
    spaDetailsIsLoading: false,
    adminDetailsIsLoading:false,
    error: null,
  },
  reducers: {
    AddUserRole: (state,action) => {
      state.userRole = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.userDetailsIsLoading = true;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.userDetails = action.payload;
        state.userDetailsIsLoading = false;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
        state.userDetailsIsLoading = false;
      }).addCase(userDetailData.pending, (state) => {
        state.userDetailsIsLoading = true;
      })
      .addCase(userDetailData.fulfilled, (state, action) => {
        state.userDetails = action.payload;
        state.userDetailsIsLoading = false;
      })
      .addCase(userDetailData.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
        state.userDetailsIsLoading = false;
      }).addCase(loginAdmin.pending, (state) => {
        state.adminDetailsIsLoading = true;
      })
      .addCase(loginAdmin.fulfilled, (state, action) => {
        state.adminDetails = action.payload;
        state.adminDetailsIsLoading = false;
      })
      .addCase(loginAdmin.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
        state.adminDetailsIsLoading = false;
      })
      .addCase(loginSpaApi.pending, (state) => {
        state.spaDetailsIsLoading = true;
      })
      .addCase(loginSpaApi.fulfilled, (state, action) => {
        state.spaDetails = action.payload;
        state.spaDetailsIsLoading = false;
      })
      .addCase(loginSpaApi.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
        state.spaDetailsIsLoading = false;
      });
  },
});
export const { AddUserRole } = userSlice.actions;
export default userSlice.reducer;
