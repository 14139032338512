import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  Navigate,
} from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  AboutUs,
  ForgotPassword,
  Home,
  Login,
  Register,
  ContactUs,
  Packages,
  Services,
  PackagesDetails,
  AdminDeshboard,
  AddPackages,
  SpaLogin,
  AdminCreateQrcode,
  UserPackage,
  AdminPackage,
  QrScanner,
  AdminLogin,
  AdminRegister,
  AdminSpaActive,
  SpaRegister,
  Profile,
  Success,
  Failed,
  SpaList
} from "../pages";
import { useSelector } from "react-redux";

function MainRouter() {

  const [role, setRole] = useState(localStorage.getItem("roles") || "");

  const userRole = useSelector((state) => state.user.userRole);

  useEffect(() => {
    if (userRole) setRole(userRole);
    console.log(userRole, "userRole");
  }, [userRole]);

  return (
    <div>
      <Router>
        <Routes>
          {role === "user" && (
            <>
              <Route path="/user/package" element={<UserPackage />} />
              <Route path="/user/scanner" element={<QrScanner />} />
            </>
          )}

          {role === "spa_owner" && (
            <>
              <Route path="/admin/package" element={<AdminPackage />} />
              <Route path="/admin/qrcode" element={<AdminCreateQrcode />} />
            </>
          )}
          {role === "admin" && (
            <>
              <Route path="/admin/dashboard" element={<AdminDeshboard />} />
              <Route path="/admin/addpackage" element={<AddPackages />} />
              <Route path="/admin/spa/active" element={<AdminSpaActive />} />
            </>
          )}
          {/* </Route> */}

          <Route path="/" element={<Home />} />
          <Route path="/payment/success" element={<Success />} />
          <Route path="/payment/failed" element={<Failed />} />
          <Route path="/spa" element={<SpaList />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/login" element={<Login />} />
          <Route path="/admin/login" element={<AdminLogin />} />
          <Route path="/spa/login" element={<SpaLogin />} />
          <Route path="/register" element={<Register />} />
          <Route path="/admin/register" element={<AdminRegister />} />
          <Route path="/spa/register" element={<SpaRegister />} />
          <Route path="/forgot/password" element={<ForgotPassword />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/packages" element={<Packages />} />
          <Route path="/packages-details" element={<PackagesDetails />} />
          <Route path="/services" element={<Services />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>

        {/* Uncomment if you want to redirect all unknown paths to home */}
      </Router>
    </div>
  );
}

export default MainRouter;
