

import React, { useEffect, useState } from "react";
import { Badge, Card, CardBody, Col, Container, Image, Row, Table } from "react-bootstrap";
import { GoPackage } from "react-icons/go";
import { GrList } from "react-icons/gr";
import { AdminHeader, AdminSidebar } from "../components";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { getUserBookSpa } from "../redux/bookspa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

function UserPackage() {
    const dispatch = useDispatch();
    const getUserSpaBook = useSelector((state) => state.bookspa.getUserSpaBook);
  
    useEffect(() => {
      if (getUserSpaBook && isObjectEmpty(getUserSpaBook)) {
        dispatch(getUserBookSpa());
      }
    }, [getUserSpaBook]);

    function isObjectEmpty(data) {
        for (let key in data) {
          if (data.hasOwnProperty(key)) {
            return false;
          }
        }
        return true;
      }
console.log(getUserSpaBook?.qrCode,"getUserSpaBook");

      useEffect(()=>{
        if(getUserSpaBook && getUserSpaBook.qrCode){
          setData(getUserSpaBook.qrCode)  
        }
      },[getUserSpaBook])
    const [data, setData] = useState([]);
console.log(data,"data");

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 4;

  const pageCount = Math.ceil(data.length / itemsPerPage);
  const offset = currentPage * itemsPerPage;
  const currentPageData = data.slice(offset, offset + itemsPerPage);

  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setCurrentPage(selectedPage);
  };
  return (
    <div className="d-flex flex-column flex-lg-row h-lg-full min-h-screen bg-gray-200 vstack">
      <AdminSidebar />
      <div className="flex-lg-1 h-lg-screen overflow-y-lg-auto">
      <AdminHeader title={"Dashboard"} />
      <Container fluid className="py-6 gx-md-12">
      <Row>
      <Card>
      {data ? (
              <>
                <Card.Body className="p-0">
                  <Table
                    responsive
                    striped
                    bordered
                    hover
                    className="table-design"
                  >
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Code Number</th>
                        <th>status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* {currentPageData.map((item) => ( */}
                      {data.map((item, index) => {
                        return (
                          <tr>
                            <td>{index + 1}</td>
                            
                            <td>{item.id}</td>
                            <td> {item.useCode ? <Badge bg="danger">Used</Badge> : <Badge bg="success">Unused</Badge>} </td>
                            <td>{item.useCode && <FontAwesomeIcon
                          icon={faEye}
                          className="position-absolute end-5 cursor-pointer"
                        //   onClick={togglePassword}
                        />}</td>
                          </tr>
                        );
                      })}

                    </tbody>
                  </Table>
                </Card.Body>
                <Card.Footer>
                  <div className="d-flex justify-content-between align-items-center">
                    <p>
                      Showing {offset + 1} to {offset + currentPageData.length}{" "}
                      of {data.length} entries
                    </p>

                    <ReactPaginate
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                </Card.Footer>
              </>
            ) : (
              <p className="text-center">No data available</p>
            )} </Card>
      </Row>
      </Container>
      </div>
    </div>
  );
}

export default UserPackage;
