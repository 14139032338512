import React from "react";
import { createRoot } from "react-dom/client";
import "./assets/style/global.scss";
import App from "./App";
import { Home } from "./pages";
// import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from "react-redux";
import store from "./redux/store"

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <Provider store={store}>
    <ToastContainer />
    <App />
    </Provider>
  </React.StrictMode>
);
