const api_domain = "http://localhost:3001/";
// const api_domain = "https://dev.tapgro.com/";

const api_host = api_domain + "";
const api_endpoints = {
  packagesListGet: "user/package",
  loginApi: "user/login",
  spaLoginApi: "spa/login",
  packagesListAdd:"user/package",
  createQr:"qrcode",
  bookSpa:"spa/book",
  getuserBookSpa:"spa/user/book",
  getAdminBookSpa:"spa/admin/book",
  adminLoginApi:"admin/login",
  getAllSpa:"admin/getspa",
  updateUserProfile:"user/update/"
};

export { api_host, api_endpoints, api_domain };
