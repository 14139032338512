import React, { useEffect, useState } from "react";
import { AddPackagesForm, AdminHeader, AdminSidebar } from "../../components";
import {
  Button,
  Card,
  Col,
  Container,
  Modal,
  Row,
  Table,
  Image
} from "react-bootstrap";
import { HiOutlinePlus, HiOutlinePlusSm } from "react-icons/hi";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { getPackage } from "../../redux/packages";

function AddPackages() {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const allPackages = useSelector((state) => state.package.allPackages);

  console.log(allPackages, "allPackages");

  useEffect(() => {
    if (allPackages && isObjectEmpty(allPackages)) {
      dispatch(getPackage());
    }
  }, [allPackages]);

  useEffect(() => {
    if (allPackages.data) {
      setData(allPackages.data);
    }
    console.log(allPackages.data, "0909090909090909090909");
  }, [allPackages]);

  function isObjectEmpty(data) {
    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  const [data, setData] = useState([]);
  console.log(data, "data", data.length);

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 4;

  const pageCount = Math.ceil(data.length / itemsPerPage);
  const offset = currentPage * itemsPerPage;
  const currentPageData = data.slice(offset, offset + itemsPerPage);

  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setCurrentPage(selectedPage);
  };

  return (
    <div className="d-flex flex-column flex-lg-row h-lg-full min-h-screen bg-gray-200 vstack">
      <AdminSidebar />
      <div className="flex-lg-1 h-lg-screen overflow-y-lg-auto">
        <AdminHeader title={"package"} />
        <Container fluid className="py-6 gx-md-12">
          <Card>
            <Card.Header>
              <Row className="gy-4">
                <Col className="col-sm hstack justify-content-between gap-4">
                  <h4>Packages</h4>
                </Col>
                <Col className="col-sm-auto">
                  <div className="hstack justify-content-end gap-3 flex-sm-row flex-column">
                    <Button variant="primary" onClick={handleShow}>
                      
                      <HiOutlinePlus className="me-2" />
                      Add
                    </Button>
                  </div>
                </Col>
              </Row>
            </Card.Header>

            {data ? (
              <>
                <Card.Body className="p-0">
                  <Table
                    responsive
                    striped
                    bordered
                    hover
                    className="table-design"
                  >
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Image</th>
                        <th>Package Name</th>
                        <th>Price</th>
                        <th>Time</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* {currentPageData.map((item) => ( */}
                      {data.map((item, index) => {
                        return (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{<Image
                          src={`http://localhost:3001/${item.image}`}
                          className="rounded-2 object h-20"
                          alt="Hospitality Tourism"
                          loading="lazy" width={200}
                        />}</td>
                            <td>{item.title}</td>
                            <td>{item.prices}</td>
                            <td>{item.time}</td>
                            <td>{item.description}</td>
                          </tr>
                        );
                      })}

                      {/* ))} */}
                    </tbody>
                  </Table>
                </Card.Body>
                <Card.Footer>
                  <div className="d-flex justify-content-between align-items-center">
                    <p>
                      Showing {offset + 1} to {offset + currentPageData.length}{" "}
                      of {data.length} entries
                    </p>

                    <ReactPaginate
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                </Card.Footer>
              </>
            ) : (
              <p className="text-center">No data available</p>
            )}
          </Card>
        </Container>
      </div>
      <Modal centered size="xl" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Packages</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddPackagesForm />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AddPackages;
