import { api_endpoints, api_host } from "./constants";

export default class API {
  constructor() {}

  static PackageList() {
    return api_host + api_endpoints.packagesListGet;
  }
  static CreateQrcodeAdd() {
    return api_host + api_endpoints.createQr;
  }
  static PackageAdd() {
    return api_host + api_endpoints.packagesListAdd;
  }
  static LoginApi() {
    return api_host + api_endpoints.loginApi;
  }
  static LoginApiData() {
    return api_host + api_endpoints.loginApi;
  }
  static SpaLoginApi() {
    return api_host + api_endpoints.spaLoginApi;
  }
  static AddBookSpa() {
    return api_host + api_endpoints.bookSpa;
  }
  static GetUserBookSpa() {
    return api_host + api_endpoints.getuserBookSpa;
  }
  static GetAdminBookSpa() {
    return api_host + api_endpoints.getAdminBookSpa;
  }
  static LoginAdminApi() {
    return api_host + api_endpoints.adminLoginApi;
  }
  static GetAllSpa() 
  {
    return api_host + api_endpoints.getAllSpa;

  }
  static UpdateUser(id){
    return api_host + api_endpoints.updateUserProfile + id;

  }
  _checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
      return response;
    } else if (response.status === 401) {
      return response;
    } else if (response.status === 400) {
      return response;
    } else {
      throw new Error(response);
    }
  }

  async fetch(url, options) {
    const headers = {
      Accept: "multipart/form-data",
      "access-control-allow-origin": "*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("therapyToken")}`, // Replace with your actual token
    };
    const response = await fetch(url, {
      headers,
      ...options,
    });
    const response_1 = await this._checkStatus(response);
    return response_1.json();
  }
}
