import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Image,
  Row,
} from "react-bootstrap";
import { MdArrowOutward, MdCurrencyRupee } from "react-icons/md";
import { PackagesDetails } from "../../pages";
import { cashfree } from "../../util/cashFree";
import { useDispatch, useSelector } from "react-redux";
import { userDetailData } from "../../redux/user";
import axios from "axios";

function PackagesCard({ data,MorePackagesButton }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state.user.userDetails);
  useEffect(() => {
    if (localStorage.getItem("therapyToken") && localStorage.getItem("roles") === 'user') {
      if (!userDetails.user) {
        dispatch(userDetailData());
      }
    }

    console.log(userDetails, "userDetails");
  }, [userDetails]);

  console.log(data, "data");
  // let version = cashfree.version();

  const getSessionId = async (item) => {
    const body = {
      packageDetails: {
        item,
      },
      userDetails: {
        user: userDetails.user,
      },
    };

    console.log(body, "body");

    try {
      const response = await axios.post(
        "http://localhost:3001/payment/paymentsdata",
        body
      );

      console.log(response, "res");
      return response.data;
    } catch (error) {
      console.error(error, "Error on getSessionId");
    }
  };

  const handlePayments = async (e, item) => {
    e.preventDefault(); // Prevent the form from submitting
    const token = localStorage.getItem("therapyToken");
    console.log(token, "token",!localStorage.getItem("therapyToken") && localStorage.getItem('roles') !== "user");

    if (localStorage.getItem('roles') !== "user") {
      return navigate("/login");
    }

    let sessionId = await getSessionId(item);
    console.log(sessionId, "sessionid");

    let checkoutOptions = {
      paymentSessionId: sessionId,
      returnUrl: "http://localhost:3001/payment/status/{order_id}",
    };

    cashfree.checkout(checkoutOptions).then(function (result) {
      if (result.error) {
        alert(result.error.message);
      }
      if (result.redirect) {
        console.log("Redirection");
        alert("Redirection");
      }
    });
  };

  return (
    <>
      <section className="mb-lg-20 mb-10">
        <Container>
          <h2 className="h1 font-bolder mb-6">Packages</h2>
          <Row className="mb-8 align-items-center">
            {data &&
              data?.data?.map((item, index) => {
                console.log(item, "item");
                return (
                  <Col lg={4}>
                    <Card className="h-full bg-gray-200">
                      <CardBody className="p-0 overflow-hidden position-relative packages-card">
                        <Image
                          src={`http://localhost:3001/${item.image}`}
                          className="image-effects rounded-top-2 transition-transform duration-500 transform position-absolute object h-full w-full image-hover-blur"
                          alt="Hospitality Tourism"
                          loading="lazy"
                        />
                        <div className="position-relative overlap-10 ms-4 mt-32">
                          <h3 className="mb-2 text-light">{item.title}</h3>
                          <p className="mb-4 text-gray-200">
                            {item.description}
                          </p>
                        </div>
                      </CardBody>
                      <CardBody className="border-top pt-4">
                        <h4 className="mb-4">
                          <MdCurrencyRupee />
                          {item.prices} / <span>{item.time}</span> minute
                        </h4>
                     <div className="text-center">
                          <Button
                            as={Link}
                            to={PackagesDetails}
                            variant="primary"
                            size="sm"
                            className="w-full"
                            onClick={(e) => handlePayments(e, item)}
                          >
                            Book Now
                          </Button>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                );
              })}
          </Row>
{MorePackagesButton &&   
          <div className="text-center">
            <Button
              variant="dark"
              as={Link}
              to="./Packages"
              size="lg"
              className="btn-animation"
            >
              <span>More Packages</span>{" "}
              <MdArrowOutward className="button-animation-icon" />
            </Button>
          </div>}
        </Container>
      </section>
    </>
  );
}

export default PackagesCard;
