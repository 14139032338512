import React from "react";
import { Footer, Header } from "../components";
import { Card, CardBody, CardHeader, CardImg, Col, Container, FloatingLabel, Form, Image, Row } from "react-bootstrap";
import { welcomeToTherapy } from "../assets/images";

function SpaList() {
  return (
    <>
      <Header />
      <Container className="mt-lg-20 mt-10 mb-6">
        <Row>
          <Col lg={4}>
            <FloatingLabel controlId="floatingInput" label="Search Location">
              <Form.Control type="text" placeholder="Search" />
            </FloatingLabel>
          </Col>
          <Col lg={4}>
            <FloatingLabel controlId="floatingPassword" label="Search Spa">
              <Form.Control type="text" placeholder="Spa" />
            </FloatingLabel>
          </Col>
        </Row>
      </Container>
      <Container className="mb-lg-20 mb-10">
      <Row>
      <Col lg={4}>
      <Card className="bg-orange-100">
        <CardHeader className="p-0 rounded-top-4">
            <Image src={welcomeToTherapy} />
        </CardHeader>
        <CardBody>
<h4 className="mb-2">spa name</h4>
<p>spa description</p>
        </CardBody>
      </Card>
      </Col>
      </Row>
      </Container>
      <Footer />
    </>
  );
}

export default SpaList;
